<template>
  <Header />
  <section class="view bg bg-img" id="uvod">
    <div class="container">
      <h2 hidden>Úvodní slovo kandidáta na starostu</h2>
      <div class="row justify-content-center">
        <div class="col-lg-8 vert handwritten">
          <p>
            <strong>Milí sousedé</strong>,<br />těší mě, že Vám není lhostejné,
            co se děje v naší obci, a že se chcete blíže seznámit s naší
            kandidátkou a programem sdružení ROZUMNĚ!
          </p>
          <p>
            Mám Žehrovice a zdejší lidi moc ráda. Pracuji v místě a dvanáct let
            spolupořádám všechny obecní kulturní akce. S většinou z Vás se
            osobně znám. Čtyři roky jsem v zastupitelstvu, poslední dva pak jako
            místostarostka.
          </p>
          <p>
            Věřím, že se schopnými a férovými lidmi z našeho sdružení, kteří
            mají chuť dále rozvíjet naši vesnici, dokážeme navázat na úspěchy a
            skvěle rozjetou práci současného vedení.
          </p>
          <p>
            Děkuji že přijdete k volbám, a budu moc ráda, když podpoříte členy
            našeho sdružení.
          </p>
          <p>Lenka Husariková, kandidátka na starostku</p>
          <span class="signature">
            <img
              src="../assets/img/podpis_b.png"
              width="250"
              alt="ilustrační podpis kandidáta na starostu"
            />
          </span>
        </div>
      </div>
    </div>
  </section>
  <section class="list" id="kandidatka">
    <div class="container">
      <h2>Kandidátka</h2>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/LenkaHusarikova.jpg"
              alt="foto Lenka Husariková"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Lenka Husariková</h3><h4>43 let, podnikatelka</h4>',
                    '<p>Vystudovala střední průmyslovou školu stavební, po maturitě krátce pracovala v oboru. Poté zaměstnána v reklamní agentuře, nyní je její spolumajitelkou.  V obci žije od roku 1985, od roku 2018 je zastupitelkou a poslední dva roky místostarostkou.</p><p>Její zájem se doposud upíral zejména k pořádání akcí pro děti i dospělé a rozvoji kulturního a komunitního života v obci, dále pak ke kontrole realizovaných stavebních zakázek. Zároveň se systematicky připravuje, aby v případě zvolení mohla plně převzít agendu spojenou s výkonem funkce uvolněné starostky.</p><p>Volné chvíle dělí mezi rodinu, cestování a rekonstrukci malého bytového domu.</p><p>Je vdaná, má dva dospělé syny.</p>'
                  )
                "
              >
                1
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>1.</strong> -->
            <h3>Lenka Husariková</h3>
            <h4>Podnikatelka</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/MarekStary.jpg"
              alt="foto Marek Starý"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Marek Starý</h3><h4>47 let, vysokoškolský pedagog</h4>',
                    '<p>Absolvent magisterského a poté doktorského studia na Právnické fakultě Univerzity Karlovy, kde v současné době vyučuje jako docent pro obor právní dějiny. Do Žehrovic se přistěhoval v roce 2004. Od roku 2006 člen zastupitelstva obce, od roku 2010 místostarosta.</p><p>Jeho úkolem je a v případě zvolení nadále bude bdít nad dodržováním právních norem, připravovat smlouvy a formulovat další dokumenty související s výkonem obecní samosprávy.</p><p>Od dětství vodák, kanoistice a dalším sportům se rekreačně věnuje ve volném čase. Další jeho zálibou je turistika, s oblibou objíždí méně známé historické památky.</p><p>Ženatý, otec dcery a syna.</p>'
                  )
                "
              >
                2
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>2.</strong> -->
            <h3><small>doc. JUDr.</small> Marek Starý<small>, Ph.D.</small></h3>
            <h4>Vysokoškolský pedagog</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/SonaCerna.jpg"
              alt="foto Soňa Černá"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Soňa Černá</h3><h4>65 let, starostka</h4>',
                    '<p>Žehrovická rodačka, vystudovala SEŠ Slaný – všeobecná ekonomie.  Pracovala jako sekretářka v Kamenouhelných dolech, poté jako finanční účetní v JZD Kamenné Žehrovice. Po roce 1989 nastoupila do České spořitelny, kde pracovala jako finanční poradce a poradce pro hypotéky do komunálních voleb v r. 2010. V letech 2006–2010 byla neuvolněnou místostarostkou obce Kamenné Žehrovice, v následujících třech volebních obdobích již starostkou obce.</p><p>Své zkušenosti z komunální politiky by ráda předala nové generaci.</p><p>Záliby: plavání, cyklistika, příroda a vnoučata.</p><p>Vdaná, má dva syny a čtyři vnoučata</p>'
                  )
                "
              >
                3
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>3.</strong> -->
            <h3>Soňa Černá</h3>
            <h4>Starostka</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/PetrBolina.jpg"
              alt="foto Petr Bolina"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Petr Bolina</h3><h4>62 let, vedoucí nákladní dopravy</h4>',
                    '<p>Rozený Kladeňák, občan Kamenných Žehrovic 22 let.  Dříve aktivní hokejista, mezinárodní rozhodčí, dnes rekreační sportovec</p><p>Podporuje ekologii, sportovní a společenské dění v obci.</p><p>Rozvedený, má dceru, syna a 2 vnoučata</p>'
                  )
                "
              >
                4
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>4.</strong> -->
            <h3>Petr Bolina</h3>
            <h4>Vedoucí nákladní dopravy Energie Kladno</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/JakubSehlik.jpg"
              alt="foto Jakub Stehlík"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Jakub Stehlík</h3><h4>35 let, manažer obchodního týmu Makro cash&carry ČR</h4>',
                    '<p>Rodilý občan Kamenných Žehrovic, vystudoval Střední hotelovou školu v Kladně, donedávna vedl fotbalový klub SK Kamenné Žehrovice. Aktivní sportovec, především fotbalista a cyklista.</p><p>Podporuje sportovní a společenské události a moderní rozvoj obce.</p><p>Ženatý, otec jednoho syna</p>'
                  )
                "
              >
                5
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>5.</strong> -->
            <h3>Jakub Stehlík</h3>
            <h4>Manažer obchodního týmu Makro cash&carry ČR</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/TomasPichner.jpg"
              alt="foto Tomáš Pichner"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Tomáš Pichner</h3><h4>30 let, pedagog Labyrintu Kladno</h4>',
                    '<p>Rodák z Kamenných Žehrovic, který získal maturitu na Sportovním gymnáziu Kladno a poté bakalářský titul na Provozně ekonomické fakultě ČZÚ v Praze. Aktivní hráč florbalu, vedoucí klubu a trenér dětí. Organizuje letní tábory, sportovní a dětské akce i kvízy pro dospělé.</p><p>Podporuje veškerou činnost pro děti a mládež v obci.</p><p>Ženatý, dítě na cestě.</p>'
                  )
                "
              >
                6
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>6.</strong> -->
            <h3><small>Bc.</small> Tomáš Pichner</h3>
            <h4>Pedagog Labyrintu Kladno</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/JitkaKlouckova.jpg"
              alt="foto Jitka Kloučková"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Jitka Kloučková</h3><h4>50 let, učitelka v mateřské školce</h4>',
                    '<p>V Kamenných Žehrovicích žije od narození (rodina je žehrovická ve třetí generaci). Úplné střední vzdělání nabyla na Gymnáziu Kladno a posléze též Střední pedagogické škole v Berouně. Pracuje jako učitelka v mateřské škole a práce je jí i koníčkem. Organizuje akce pro děti, účastní se letních táborů, je šéftrenérkou Dětí na startu. Dalšími zálibami jsou cestování, četba, historie, příroda.</p><p>S radostí pozoruje rozkvět obce a chce k němu přispět.</p><p>Vdaná, jeden syn.</p>'
                  )
                "
              >
                7
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>7.</strong> -->
            <h3>Jitka Kloučková</h3>
            <h4>Učitelka v mateřské školce</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/PavelCermak.jpg"
              alt="foto Pavel Čermák"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Bc. Pavel Čermák</h3><h4>49 let, bankovní poradce Corporate</h4>',
                    '<p>Bakalářský titul získal v oboru Ekonomika a management na Středočeském vysokoškolském institutu.</p><p>Do Kamenných Žehrovic se s rodinou přistěhoval v roce 2004.</p><p>Brzy poté začal aktivně působit v místním fotbalovém klubu, za který hraje dodnes. Zároveň se od letošního roku podílí na vedení klubu.</p><p>Má rád sport. Kromě zmiňovaného fotbalu třeba ještě tenis a cyklistiku.</p><p>Je ženatý a má dvě dospělé děti.</p>'
                  )
                "
              >
                8
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>8.</strong> -->
            <h3><small>Bc.</small> Pavel Čermák</h3>
            <h4>Bankovní poradce Corporate</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 member">
          <div class="region">
            <img
              class="img-fluid"
              src="../assets/img/people/MichaelaMarkova.jpg"
              alt="foto Michaela Marková"
            />
            <div class="region-hover">
              <a
                href="#"
                class="modal-content-inside"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>Michaela Marková Borčová</h3><h4>35 let, na rodičovské dovolené</h4>',
                    '<p>Rodačka z Kamenných Žehrovic (čtvrtá generace). Nabyla středoškolské vzdělání s maturitou na obchodní akademii. Pracuje jako manikérka a nehtová designérka při rodičovské dovolené. Dříve dělala instruktorku spinningu a trenérku Dětí na startu.</p><p>Podporuje sportovní a kulturní akce pro děti a mládež v obci.</p><p>Vdaná, má dceru a syna.</p>'
                  )
                "
              >
                9
              </a>
            </div>
          </div>
          <div class="region-info text-center">
            <!-- <strong>9.</strong> -->
            <h3>Michaela Marková Borčová</h3>
            <h4>Na rodičovské dovolené</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="view bg crs" id="program">
    <div
      id="carouselExampleFade"
      class="carousel slide carousel-fade"
      data-bs-ride="carousel"
    >
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="5000">
          <picture>
            <source
              media="(min-width:992px)"
              srcset="../assets/img/carousel/K_Zehrovice_6.jpg 1920w"
              sizes="(min-width:992px) 1920px"
            />
            <source
              media="(min-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_6_md.jpg"
              sizes="(min-width:768px) 992px"
            />
            <source
              media="(max-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_6_sm.jpg"
            />
            <img
              src="../assets/img/carousel/K_Zehrovice_6.jpg"
              class="d-block"
              alt="první ilustrační obrázek volebního programu"
            />
          </picture>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <picture>
            <source
              media="(min-width:992px)"
              srcset="../assets/img/carousel/K_Zehrovice_9.jpg 1920w"
              sizes="(min-width:992px) 1920px"
            />
            <source
              media="(min-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_9_md.jpg"
              sizes="(min-width:768px) 992px"
            />
            <source
              media="(max-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_9_sm.jpg"
            />
            <img
              src="../assets/img/carousel/K_Zehrovice_9.jpg"
              class="d-block"
              alt="druhý ilustrační obrázek volebního programu"
            />
          </picture>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <picture>
            <source
              media="(min-width:992px)"
              srcset="../assets/img/carousel/K_Zehrovice_10.jpg 1920w"
              sizes="(min-width:992px) 1920px"
            />
            <source
              media="(min-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_10_md.jpg"
              sizes="(min-width:768px) 992px"
            />
            <source
              media="(max-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_10_sm.jpg"
            />
            <img
              src="../assets/img/carousel/K_Zehrovice_10.jpg"
              class="d-block"
              alt="třetí ilustrační obrázek volebního programu"
            />
          </picture>
        </div>
        <div class="carousel-item" data-bs-interval="5000">
          <picture>
            <source
              media="(min-width:992px)"
              srcset="../assets/img/carousel/K_Zehrovice_14.jpg 1920w"
              sizes="(min-width:992px) 1920px"
            />
            <source
              media="(min-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_14_md.jpg"
              sizes="(min-width:768px) 992px"
            />
            <source
              media="(max-width:768px)"
              srcset="../assets/img/carousel/K_Zehrovice_14_sm.jpg"
            />
            <img
              src="../assets/img/carousel/K_Zehrovice_14.jpg"
              class="d-block"
              alt="čtvrtý ilustrační obrázek volebního programu"
            />
          </picture>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row justify-content-end">
        <div class="col-lg-6">
          <h2>Náš volební program</h2>
          <div class="row program">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#1 Dotace</h3>',
                    '<p>Alfou i omegou rozvoje jsou i ve 21. století peníze. Z evropských fondů i z národního rozpočtu plynou na komunitní rozvoj nemalé částky, které je třeba umět vyčerpat. Úspěšnost vedení obce se ve velké míře poměřuje právě tím, kolik peněz dokáže z veřejných fondů pro její rozvoj odčerpat. Důležitým předpokladem je připravenost, což předpokládá mít připravené příslušné projekty a pečlivě monitorovat výzvy. V minulých obdobích jsme v tomto směru byli poměrně úspěšní a intenzivní pozornost hodláme této problematice věnovat i nadále.</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">payments</span>
                <h4>#1 Dotace</h4>
              </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#2 Komunikace</h3>',
                    '<p>Během dvou posledních dekád se podoba komunikační sítě v obci výrazně posunula, nevyasfaltované ulice již dnes představují naprostou menšinu. Byli bychom rádi, kdyby i ty poslední získaly během příštího volebního období nový povrch a prach a bláto zůstaly jen nostalgickou vzpomínkou na doby minulé. Vedle ulic a chodníků chceme nemenší pozornost věnovat i dalším veřejným prostranstvím, tak, aby při toulání po vsi nenarazil náhodný kolemjdoucí na veřejně přístupné zákoutí, za které bychom se museli stydět.</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">add_road</span>
                <h4>#2 Komunikace</h4>
              </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#3 Cyklostezka</h3>',
                    '<p>Během tohoto volebního období se nám podařilo iniciovat a zahájit dlouhodobý proces pozemkových úprav. V jeho důsledku by se do vlastnictví obce měla dostat přinejmenším část lesních a polních cest v extravilánu obce. Umožní-li to nové vlastnické poměry, budeme ze všech sil usilovat o vybudování cyklostezky, která by Žehrovice propojila se stávající sítí cyklotras v jejich okolí. V tomto ohledu se nabízí především cesta kolem „Šmanťáku“, která by pod haldou Nosek vyústila na cyklostezku Tuchlovice – Lány. Dále kolem Turyňského rybníka a v neposlední řadě od hřbitova přes les směrem k rybníku na Doksech. </p>'
                  )
                "
              >
                <span class="material-symbols-outlined">directions_bike</span>
                <h4>#3 Cyklostezka</h4>
              </a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#4 Revitalizace náměstí Míru</h3>',
                    '<p>Náměstí Míru patří mezi největší veřejná prostranství na území naší obce. Jeho současnou podobu je možno vnímat jako uspokojivou, zároveň je to ale prostor, který je do určité míry „mrtvý“. Myslíme si přitom, že by se i zde mohl rozvinout čilejší komunitní život. Předpokladem je „oživit“ zelené plochy mezi chodníky a obytnou zástavbou a nabídnout zde dětem hrací koutek, dospělým workoutové prvky a seniorům třeba vymazlený plácek pro pétanque, kubb nebo jiné nenáročné pohybové aktivity, které lze pěstovat v každém věku.  </p>'
                  )
                "
              >
                <span class="material-symbols-outlined">handyman</span>
                <h4>#4 Revitalizace náměstí Míru</h4>
              </a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#5 Sportoviště v areálu školy</h3>',
                    '<p>Již v minulém období připravovalo vedení obce velkou investici – výstavbu multifunkční sportovní haly v areálu Základní školy. Bohužel, enormní růst cen ve stavebnictví zapříčinil, že se původní rozpočty zcela rozešly s realitou a v současné době by se náklady na takovou halu pohybovaly v astronomických číslech. Podpora sportovního vyžití dětí i dospělých zůstává ale v našich očích velmi smysluplným prostorem pro investice z obecního rozpočtu (tím spíše, budou-li podpořeny dotačními prostředky). Není-li reálné sportoviště kryté, rádi bychom škole i veřejnosti dali k dispozici alespoň důstojné sportoviště venkovní včetně atletického oválu.</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">sports_soccer</span>
                <h4>#5 Sportoviště v areálu školy</h4>
              </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#6 Obecní byty</h3>',
                    '<p>Již delší dobu jsou obce státem vyzývány, aby podporovaly bydlení pro mladé rodiny, a stejně tak vytvářely možnosti bydlení pro sociálně potřebné. Obec v současné době vlastní tři byty (v Dělnickém domě, ve zdravotním středisku a z minulého volebního období též v prostorách bývalé pošty). Rádi bychom tento počet v rámci možností navýšili, přičemž z reálných lokalit ve vlastnictví obce se nabízejí buď podkroví Dělnického domu, nebo další části zdravotnického střediska, které svému původnímu účelu slouží jen zčásti.</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">house</span>
                <h4>#6 Obecní byty</h4>
              </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#7 Fotovoltaika</h3>',
                    '<p>Současná energetická krize je problémem celospolečenským a nevyhýbá se ani městům i obcím. A není jediný důvod, proč by se i ony neměly zabývat hledáním cest, jak více využít obnovitelných zdrojů a optimalizovat své výdaje na energie. Za perspektivní cestu považujeme především využití sluneční energie, a rádi bychom tedy po konzultaci s odborníky osadili střechy všech obecních budov fotovoltaickými panely a akumulací jimi vyrobené energie do baterií odpovídající kapacity dosáhli alespoň částečné energetické soběstačnosti.</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">bolt</span>
                <h4>#7 Fotovoltaika</h4>
              </a>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#mainModal"
                @click="
                  modal(
                    '<h3>#8 Podpora kultury</h3>',
                    '<p>V uplynulých letech se nám podařilo zaplnit kalendář pravidelnými akcemi, které podle našeho názoru občané přijali za své a vnímají je jako samozřejmou součást života obce (Dětský den, Drvotova stezka, Rozsvěcení vánočního stromku, Silvestrovská sousedská). Do budoucna bychom rádi obnovili tradici zářijových Trhů lidových řemesel, přerušenou v důsledku pandemie covid, pokračovali v pořádání akcí výše zmíněných a na základě průzkumů veřejného mínění se třeba pokusili i o založení nějaké tradice nové. Proč ne třeba masopust nebo obecní ples?</p>'
                  )
                "
              >
                <span class="material-symbols-outlined">theater_comedy</span>
                <h4>#8 Podpora kultury</h4>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div
    class="modal fade"
    id="mainModal"
    tabindex="-1"
    aria-labelledby="mainModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <div
            class="modal-title"
            id="mainModalLabel"
            v-html="modal_title"
          ></div>
          <button
            type="button"
            class="btn btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="material-symbols-outlined">close</span>
          </button>
        </div>
        <div class="modal-body" v-html="modal_body"></div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      modal_title: "",
      modal_body: "",
    };
  },
  methods: {
    modal(title, body) {
      this.modal_title = title;
      this.modal_body = body;
    },
  },
};
</script>
