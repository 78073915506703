<template>
  <router-view />
  <span class="material-symbols-outlined" id="back-to-top" @click="up()">
    keyboard_arrow_up
  </span>
</template>

<script>
export default {
  name: "App",
  methods: {
    up() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
