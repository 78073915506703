<template>
  <footer>
    <div class="container">
      <div class="row creds">
        <div class="col-lg-4 col-md-6 col-sm-12">
          <img
            class="img-fluid logo"
            src="../assets/img/final-400.png"
            alt="logo volte rozumně"
          />
        </div>
        <div class="offset-md-0 offset-lg-2 col-lg-3 col-md-3">
          <ul class="ms-auto navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#arch1">Úvod</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#arch2">Kandidátka</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#arch3">Program</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3">
          <ul class="ms-auto navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="mailto:info@volterozumne.cz">
                <span class="material-symbols-outlined">mail</span>
                info@volterozumne.cz
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="https://www.facebook.com/profile.php?id=100084752874069"
                target="_blank"
              >
                <img
                  class="img-fluid facebook"
                  src="../assets/img/facebook.png"
                  alt="Rozumně facebook"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row copy">
        <div class="col-sm-12">
          <small>
            Vytvořil <strong>Martin Zelenka</strong> &
            <a href="https://macronsoftware.cz">Macron Software</a>
          </small>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBottom",
};
</script>
