<template>
  <header>
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a
          class="navbar-brand"
          href="#"
          aria-label="úvodní stránka volte rozumně"
        >
          <img
            class="img-fluid logo"
            src="../assets/img/final-600.png"
            width="600"
            alt="logo volte rozumně"
          />
        </a>
        <div id="navbarNav">
          <ul class="ms-auto navbar-nav">
            <li class="nav-item">
              <a class="nav-link" href="#uvod">Úvod</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#kandidatka">Kandidátka</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#program">Program</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <picture>
      <source
        media="(min-width:992px)"
        srcset="../assets/img/Spolecna_1-min.jpg 1920w"
        sizes="(min-width:992px) 1920px"
      />
      <source
        media="(min-width:768px)"
        srcset="../assets/img/Spolecna_1-min_md.jpg"
        sizes="(min-width:768px) 992px"
      />
      <source
        media="(max-width:768px)"
        srcset="../assets/img/Spolecna_1-min_sm.jpg"
      />
      <img
        src="../assets/img/Spolecna_1-min.jpg"
        class="imgbg img-fluid"
        alt="úvodní společná fotka"
      />
    </picture>
    <div class="mid">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1 hidden>Volte rozumně!</h1>
            <span>
              <a href="#arch1">
                <span class="material-symbols-outlined">
                  keyboard_arrow_down
                </span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderTop",
};
</script>
